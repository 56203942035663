<template>
  <div class="opsReport-action">
    <ml-tip :showHeader="false" content="生成报告" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form
        ref="opsReportFormRef"
        inline
        labelWidth="90px"
        :model="searchData"
        :rules="opsReportFormRules"
      >
        <!-- 阿米巴 -->
        <ml-select
          prop="companyId"
          placeholder="请选择阿米巴"
          :options="unitOptions"
          label="阿米巴:"
          keyName="companyId"
          labelName="companyName"
          valueName="companyId"
          v-model="searchData.companyId"
          style="margin-bottom: 0"
        />
        <!-- 所属项目 -->
        <ml-select
          prop="projectId"
          placeholder="请选所属项目"
          :options="projectData"
          label="所属项目:"
          keyName="projectId"
          labelName="projectName"
          valueName="projectId"
          v-model="searchData.projectId"
          :style="{ marginBottom: 0 }"
        />
        <!-- 日期 -->
        <ml-date-picker
          prop="reportTime"
          placeholder="报告日期"
          label="报告日期:"
          type="month"
          value-format="YYYY-MM"
          v-model="searchData.reportTime"
          :style="{ marginBottom: 0, marginRight: '40px' }"
        />
        <!-- 搜索按钮 -->
        <el-button type="primary" :style="{ 'margin-bottom': 0 }" @click="getDetail"
          >查询</el-button
        >
      </ml-form>
    </ml-tip>
    <ml-tip
      :showHeader="false"
      style="border: 0"
      class="default-tip"
      v-if="projectInfoData.projectName"
    >
      <div class="tip-content">
        <div class="tip-title">{{ projectInfoData.projectName }}</div>
        <div class="tip-des">{{ formData.reportTime }}月 运维报告</div>
      </div>
      <div class="item-title">一, 概况</div>
      <el-descriptions class="margin-top" :column="2" border label-class-name="el-desc-label">
        <el-descriptions-item label="合同名称" label-class-name="el-desc-label">
          {{ projectInfoData.contractName }}
        </el-descriptions-item>
        <el-descriptions-item label="项目编号" label-class-name="el-desc-label">
          {{ projectInfoData.projectCode }}
        </el-descriptions-item>
        <el-descriptions-item label="运维组织" label-class-name="el-desc-label">
          {{ projectInfoData.companyName }}
        </el-descriptions-item>
        <el-descriptions-item label="建设单位" label-class-name="el-desc-label">
          {{ projectInfoData.constructionUnit }}
        </el-descriptions-item>
        <el-descriptions-item label="项目状态" label-class-name="el-desc-label">
          {{ projectInfoData.state === 'Y' ? '正常' : '失效' }}
        </el-descriptions-item>
        <el-descriptions-item label="合同额" label-class-name="el-desc-label">
          <div class="show-box">
            <span v-if="showMoney">******</span>
            <span v-else>{{ projectInfoData.opsExpenses }}</span>
            <img
              :src="require('@/assets/image/icon-show.png')"
              alt=""
              srcset=""
              v-if="showMoney"
              @click="showMoney = false"
              class="icon-show"
            />
            <img
              :src="require('@/assets/image/icon-hide.png')"
              alt=""
              srcset=""
              v-else
              @click="showMoney = true"
              class="icon-show"
            />
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="运维负责人" label-class-name="el-desc-label">
          {{ projectInfoData.projectManager }}
        </el-descriptions-item>
        <el-descriptions-item label="联系电话" label-class-name="el-desc-label">
          {{ projectInfoData.managerPhone }}
        </el-descriptions-item>
        <el-descriptions-item label="实体资产" label-class-name="el-desc-label">
          {{ projectInfoData.physicalAssets }}个
        </el-descriptions-item>
        <el-descriptions-item label="虚拟资产" label-class-name="el-desc-label">
          {{ projectInfoData.virtualAssets }}个
        </el-descriptions-item>
        <el-descriptions-item label="服务内容" label-class-name="el-desc-label">
          {{ projectInfoData.serviceContent }}
        </el-descriptions-item>
      </el-descriptions>
      <div class="item-title">二, 故障</div>
      <div class="breakdown-box">
        <div class="bradkdow-item">
          <div class="bradkdow-title">
            {{ formData.reportTime }}{{ projectInfoData.projectName }}故障统计图
          </div>
          <div class="map-box">
            <ml-echarts
              id="a232"
              v-if="deviceData.detailList.length > 0"
              :option="reportOption(deviceData.detailList)"
            />
          </div>
        </div>
        <div class="bradkdow-item">
          <el-table :data="deviceData.wholeList" style="width: 100%" border>
            <el-table-column align="center" label="故障类型" prop="severity" />
            <el-table-column align="center" label="数量" prop="total" />
            <el-table-column align="center" label="已修复" prop="fixedNum" />
            <el-table-column align="center" label="完成率" prop="completionRate">
              <template #default="scope">
                {{ scope.row.completionRate ? scope.row.completionRate * 100 : 0 }}%
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="item-title">三, 巡检</div>
      <div class="breakdown-box">
        <div class="bradkdow-item">
          <div class="bradkdow-title">
            {{ formData.reportTime }}{{ projectInfoData.projectName }}巡检统计图
          </div>
          <div class="map-box">
            <ml-echarts id="a232" :option="reportPieOption(countData)" />
          </div>
        </div>
        <div class="bradkdow-item">
          <el-descriptions class="margin-top" :column="2" border>
            <el-descriptions-item label="巡检量">
              {{ countData.total }}
            </el-descriptions-item>
            <el-descriptions-item
              label="已完成"
              class-name="desc-item"
              labelClassName="desc-label-item"
            >
              <el-descriptions class="margin-top" :column="1" border>
                <el-descriptions-item label="时内">
                  {{ countData.completeOnTimeNum }}
                </el-descriptions-item>
                <el-descriptions-item label="超时">
                  {{ countData.completeTimeoutNum }}
                </el-descriptions-item>
              </el-descriptions>
            </el-descriptions-item>
            <el-descriptions-item label="完成率">
              {{ countData.rate * 100 }}%
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <div class="item-title">四, 月度总结</div>
      <el-input
        v-model="formData.reportSummary"
        :rows="4"
        type="textarea"
        placeholder="请输入月度总结"
      />
    </ml-tip>

    <!-- 提交按钮 -->
    <div class="btn-box" v-if="projectInfoData.projectName">
      <ml-form>
          <ml-button
          submitText="提交"
          submitIcon="el-icon-upload2"
          cancelIcon="el-icon-circle-close"
          cancelText="返回上一页"
          @click-submit="clickSubmit"
          @click-cancel="clickCancel"
        />
      </ml-form>
      
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { searchParams, getTabberData } from '@/utils'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import reportOption from '@utils/viewData/report'
import reportPieOption from '@utils/viewData/reportPie'
export default {
  name: 'opsReportAction',
  setup() {
    const router = useRouter()
    const { commit, getters, dispatch } = useStore()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    const getMemoryPage = computed(() => getters.getMemoryPage)
    /* 开始 搜索 **********************************/
    // 搜索条件
    const showMoney = ref(true)
    const opsReportFormRef = ref()
    const searchDataRef = ref()
    const searchData = reactive({
      reportTime: getMemoryPage.value.searchData.reportTime || '',
      companyId: getMemoryPage.value.searchData.companyId || '',
      projectId: getMemoryPage.value.searchData.projectId || ''
    })
    const formData = reactive({
      reportTime: '',
      reportType: 3,
      projectId: '',
      reportSummary: '',
      companyId: ''
    })
    const deviceData = reactive({
      detailList: [],
      wholeList: []
    })
    const projectInfoData = ref({})
    const countData = ref({})
    // 校验
    const opsReportFormRules = {
      companyId: [{ required: true, message: '请选择阿米巴', trigger: 'change' }],
      projectId: [{ required: true, message: '请选择所属项目', trigger: 'change' }],
      reportTime: [{ required: true, message: '请选择报告日期', trigger: 'change' }]
    }
    // 单位配置
    const unitOptions = ref([])
    // 所属项目配置
    const projectData = ref([])
    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = {}
      if (isBusinessDepartment.value && companyId) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }
    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {
        noCompanyCode: 'business_department'
      })
      unitOptions.value = data || []
    })
    watch(
      () => searchData.companyId,
      newvalue => {
        if (newvalue) {
          searchData.projectId = ''
          projectInfoData.value = {}
          getProjectData(newvalue)
        }
      }
    )
    watch(
      () => searchData.projectId,
      newvalue => {
        if (newvalue) {
          projectInfoData.value = {}
        }
      }
    )
    watch(
      () => searchData.reportTime,
      newvalue => {
        if (newvalue) {
          projectInfoData.value = {}
        }
      }
    )
    const getProjectInfoHandle = async () => {
      const searchDataParams = searchParams(searchData)
      const { data } = await getTabberData(dispatch, 'fetchReportGetProjectInfo', {
        projectId: searchDataParams.projectId
      })
      projectInfoData.value = data
      formData.reportTime = searchData.reportTime
      formData.projectId = searchData.projectId
      formData.companyId = searchData.companyId
    }
    const getDeviceErrorCountHandle = async () => {
      const searchDataParams = searchParams(searchData)
      const { data } = await getTabberData(dispatch, 'fetchGetDeviceErrorCount', {
        projectId: searchDataParams.projectId,
        reportType: 3,
        reportTime: searchDataParams.reportTime
      })
      deviceData.detailList = data.detailList
      deviceData.wholeList = data.wholeList
    }
    const getInspectCountHandle = async () => {
      const searchDataParams = searchParams(searchData)
      const { data } = await getTabberData(dispatch, 'fetchGetInspectCount', {
        projectId: searchDataParams.projectId,
        reportType: 3,
        reportTime: searchDataParams.reportTime
      })
      countData.value = data
    }
    const getDetail = async () => {
      opsReportFormRef.value.CustomFormRef.validate().then(() => {
        getProjectInfoHandle()
        getDeviceErrorCountHandle()
        getInspectCountHandle()
      })
    }
    const clickSubmit = () => {
      opsReportFormRef.value.CustomFormRef.validate().then(() => {
        if (!formData.reportSummary) {
          commit('setError', {
            status: true,
            title: '',
            message: '请填写报告总结',
            type: 'warning'
          })
          return
        }
        dispatch('fetchGenerateOpsReport', formData).then(data => {
          if (data && data.code === 200) {
            commit('setError', {
              status: true,
              title: data.message,
              message: '正在获取新的数据...',
              type: 'success'
            })
            router.push({ path: '/opsReport' })
          }
        })
      })
    }
    const clickCancel = () => {
      router.push({ path: '/opsReport' })
    }
    return {
      searchDataRef,
      searchData,
      unitOptions,
      projectData,
      getDetail,
      opsReportFormRules,
      opsReportFormRef,
      projectInfoData,
      formData,
      showMoney,
      deviceData,
      countData,
      clickSubmit,
      clickCancel,
      reportOption,
      reportPieOption
    }
  }
}
</script>

<style lang="scss" scoped>
.default-tip {
  margin-top: 10px;
}
.tip-title,
.tip-des {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}
.show-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.icon-show {
  width: 26px;
  height: 26px;
  cursor: pointer;
}
.item-title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}
.breakdown-box {
  display: flex;
}
.bradkdow-item {
  flex: 1;
}
.btn-box {
  background: #fff;
  padding: 10px 20px;
}
.bradkdow-item {
  height: 290px;
}
.bradkdow-title {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  text-align: center;
}
.map-box {
  height: 265px;
}
</style>
<style>
.el-desc-label {
  width: 120px;
}
.desc-label-item {
  width: 120px;
}
.desc-item {
  padding: 0 !important;
}
</style>
