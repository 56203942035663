export default (data) => {
  let nData = []
  if (data) {
    const {
      total,
      completeNum,
      completeOnTimeNum,
      completeTimeoutNum,
    } = data
    nData = [{
        name: '未完成',
        value: total - completeNum
      },
      {
        name: '已完成-时内',
        value: completeOnTimeNum
      },
      {
        name: '已完成-超时',
        value: completeTimeoutNum
      }
    ]
  }
  return {
    tooltip: {
      trigger: 'item',
      formatter: '{b} : {d}%'
    },
    legend: {
      bottom: '5px',
      textStyle: {
        color: '#333',
        fontSize: 12
      },
      itemWidth: 22, // 设置宽度
      itemHeight: 16, // 设置高度
      itemGap: 12 // 设置间距
    },
    color: ['#539CFA', '#30CECE', '#FFD600'],
    series: [{
      // name: 'Nightingale Chart',
      type: 'pie',
      center: ['50%', '50%'],
      bottom: 25,
      label: {
        show: true,
        formatter: p => `${p.name}: ${p.value}`,
        color: '#333',
        fontSize: 12
      },
      emphasis: {
        label: {
          show: true
        }
      },
      itemStyle: {
        borderRadius: 3
      },
      data: nData
    }]
  }
}