import * as echarts from 'echarts'
import {
  isToFixed
} from '../index'

export default (data = [], typeIndex) => {
  const xAxisData = []
  const levelCount1 = []
  const levelCount2 = []
  const levelCount3 = []
  const levelCount4 = []
  data.map(item => {
    xAxisData.push(item.createTime)
    levelCount1.push(isToFixed(item.severityList[0].total, 1))
    levelCount2.push(isToFixed(item.severityList[1].total, 1))
    levelCount3.push(isToFixed(item.severityList[2].total, 1))
    levelCount4.push(isToFixed(item.severityList[3].total, 1))
  })
  return {
    grid: {
      top: '20px',
      left: '4%',
      right: '2%',
      bottom: '20px',
      containLabel: true
    },
    legend: {
      bottom: '5px',
      textStyle: {
        color: '#333',
        fontSize: 12
      },
      itemWidth: 22, // 设置宽度
      itemHeight: 16, // 设置高度
      itemGap: 12 // 设置间距
    },
    tooltip: {
      trigger: 'axis',
      formatter: p => {
        let div = ''
        p.map((item, index) => {
          if (index === 0) {
            div += `${item.name}
              <br />
              ${item.seriesName}:${item.value}
            `
          } else {
            div += `<br />
            ${item.seriesName}:${item.value}
          `
          }
        })
        return div
      }
    },
    xAxis: [{
      show: true,
      data: xAxisData,
      axisLabel: {
        show: true,
        margin: 8,
        color: '#333',
        interval: 'auto',
        rotate: 30,
        textStyle: {
          fontSize: 12
        }
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#333'
        }
      },
      axisTick: {
        show: true,
        alignWithLabel: true,
        lineStyle: {
          color: '#333'
        }
      }
    }],
    yAxis: [{
      type: 'value',
      boundaryGap: ['0', '2%'],
      minInterval: 1,
      axisLabel: {
        color: '#A7A7A7',
        textStyle: {
          fontSize: 12
        }
      },
      axisLine: {
        show: true,
        color: '#A7A7A7'
      }
    }],
    series: [{
        name: '轻微',
        type: 'bar',
        data: levelCount1,
        stack: 'one',
        barWidth: '15px',
        itemStyle: {
          color: '#46A805'
        },
        label: {
          normal: {
            show: false,
            position: 'top',
            formatter: e => {
              return e.value === 0 ? '' : e.value
            },
            fontSize: 12,
            color: '#fff'
            // offset: [4, -5]
          }
        }
      },
      {
        name: '一般',
        type: 'bar',
        data: levelCount2,
        stack: 'one',
        barWidth: '15px',
        itemStyle: {
          color: '#FFD600' // 0% 处的颜色
        },
        label: {
          normal: {
            show: false,
            position: 'top',
            formatter: e => {
              return e.value === 0 ? '' : e.value
            },
            fontSize: 12,
            color: '#fff'
            // offset: [4, -5]
          }
        }
      },
      {
        name: '严重',
        type: 'bar',
        data: levelCount3,
        stack: 'one',
        barWidth: '15px',
        itemStyle: {
          color: '#FF7B00' // 0% 处的颜色
        },
        label: {
          normal: {
            show: false,
            position: 'top',
            formatter: e => {
              return e.value === 0 ? '' : e.value
            },
            fontSize: 12,
            color: '#fff'
            // offset: [4, -5]
          }
        }
      },
      {
        name: '灾难',
        type: 'bar',
        data: levelCount4,
        stack: 'one',
        barWidth: '15px',
        itemStyle: {
          color: '#EB110A' // 0% 处的颜色
        },
        label: {
          normal: {
            show: false,
            position: 'top',
            formatter: e => {
              return e.value === 0 ? '' : e.value
            },
            fontSize: 12,
            color: '#fff'
            // offset: [4, -5]
          }
        }
      }
    ]
  }
}